<template>
  <!-- 制作答题卡页面（弃用） -->
  <div>
    <div class="content_main">
      <div class="template_type">
        <span>{{typeList[Number(activeTab)]}}</span>
        <div class="btn_wrap">
          <div class="btn"
               v-if="showApplyStatus.includes(form.status)"
               @click="apply">申请制作答题卡</div>
          <div class="btn"
               v-if="form.status == 4"
               @click="cancelApply">取消申请</div>
        </div>
      </div>

      <!-- <el-popconfirm title="重新选择模板类型将不保存当前页面操作，是否继续"
                     @confirm="showTypeDialog">
        <el-button slot="reference"
                   style="margin-bottom:10px">重新选择模板类型</el-button>
      </el-popconfirm> -->

      <div class="box">

        <!-- 通用模板 -->
        <!-- <div v-show="activeTab=='0'">
          <el-row>
            <el-col :gutter="10"
                    style="margin-top:15px">
              <el-col :span="16">
                <el-button type="primary"
                           @click="openFile('https://sunraydtk.oss-cn-beijing.aliyuncs.com/temp/shanli_template.xlsx')">导出模板</el-button>
                <el-button type="primary"
                           @click="openFile('https://sunraydtk.oss-cn-beijing.aliyuncs.com/temp/shanli_dtk_standard.pdf')">导出制作规范</el-button>
              </el-col>
              <el-col :span="8">
                <div class="btn_wrap"
                     style="justify-content: right;">
                  <div class="btn"
                       v-if="showApplyStatus.includes(form.status)"
                       @click="apply">申请制作答题卡</div>
                  <div class="btn"
                       v-if="form.status == 4"
                       @click="cancelApply">取消申请</div>
                </div>
              </el-col>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <div class="btn_wrap">

                <el-button v-if="!form.pdf_file"
                           class="btn"
                           @click="importDialog(0)">上传文件</el-button>
               
                <div class="btn"
                     v-else
                     @click="importDialog(0)">重新上传</div>
                
              </div>

              <div v-if="form.excel_file"
                   style="margin-top:10px">
                <el-tag>excel:{{form.excel_file}}</el-tag>
              </div>
              <div v-if="form.pdf_file"
                   style="margin-top:10px">
                <el-tag>pdf:{{form.pdf_file}}</el-tag>
              </div>
            </el-col>
          </el-row>
        </div> -->

        <!-- 1.推荐模板 -->
        <div v-show="activeTab=='1'">
          <div class="template_list">
            <div style="display: flex;justify-content: space-between;    align-items: center;s">
              <div class="title">选择模板</div>
              <el-button type="primary"
                         size="small"
                         @click="openFile('https://sunraydtk.oss-cn-beijing.aliyuncs.com/temp/shanli_dtk_standard_district_template.pdf')">查看制作规范</el-button>
            </div>

            <template>
              <el-table :data="templateData"
                        border
                        style="width: 100%">
                <el-table-column prop="name"
                                 label="模板名"
                                 align="center">
                </el-table-column>
                <el-table-column prop="create_time"
                                 label="创建时间"
                                 align="center">
                </el-table-column>

                <el-table-column label="操作"
                                 align="center">
                  <template slot-scope="scope">
                    <el-button @click="useTemplate(scope.$index,scope.row)"
                               type="text"
                               class="text_btn"
                               :disabled="hasSelectId!=''">直接使用</el-button>
                    <el-button @click="cancelUseTemplate(scope.$index,scope.row)"
                               type="text"
                               class="text_btn"
                               v-show="hasSelectId==scope.row.sheet_id">取消使用</el-button>
                    <el-button type="text"
                               @click="openFile(scope.row.excel_file||scope.row.word_file)"
                               class="text_btn">导出</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </div>
          <div v-show="!hasSelectId"
               class="btn_upload">
            <span>尺寸:</span>
            <el-radio-group v-model="size">
              <el-radio :label="0">A3</el-radio>
              <el-radio :label="1">A4</el-radio>
            </el-radio-group>

          </div>
          <div class="btn_upload"
               v-show="!hasSelectId">

            <span>上传:</span>

            <el-button class="btn"
                       v-if="!form.pdf_file"
                       @click="importDialog(1)">上传文件</el-button>

            <el-button class="btn"
                       v-else
                       @click="importDialog(1)">重新上传</el-button>
          </div>
        </div>
        <!-- 2.自制模板 -->
        <div v-show="activeTab=='2'">
          <div class="template_list">

            <div style="display: flex;justify-content: space-between;    align-items: center;s">
              <div class="title">选择模板</div>
              <el-button type="primary"
                         size="small"
                         @click="openFile('https://sunraydtk.oss-cn-beijing.aliyuncs.com/temp/shanli_dtk_standard_self_template.pdf')">查看制作规范</el-button>
            </div>
            <template>
              <el-table :data="sizeData"
                        border
                        style="width: 100%">
                <el-table-column prop="size"
                                 label="模板尺寸"
                                 align="center">
                </el-table-column>

                <el-table-column label="操作"
                                 align="center">
                  <template slot-scope="scope">

                    <el-button type="text"
                               class="text_btn"
                               @click="openFile(scope.row.url)">导出</el-button>
                    <el-button type="text"
                               class="text_btn"
                               @click="openFile(scope.row.url2)">导出(可批改)</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>

            <div v-show="!hasSelectId"
                 class="btn_upload">
              <span>尺寸:</span>
              <el-radio-group v-model="size">
                <el-radio :label="0">A3</el-radio>
                <el-radio :label="1">A4</el-radio>
              </el-radio-group>

            </div>
          </div>

          <div class="btn_upload">
            <span>上传:</span>

            <el-button class="btn"
                       v-if="!form.pdf_file"
                       @click="importDialog(1)">上传文件</el-button>

            <el-button class="btn"
                       v-else
                       @click="importDialog(1)">重新上传</el-button>
          </div>
        </div>

        <div v-show="fileList.excel||fileList.world||fileList.pdf">已选择文件：
          <div v-show="fileList.excel">
            excel:<el-tag>{{fileList.excel?fileList.excel.name:''}}</el-tag>
          </div>
          <div v-show="fileList.word"> word:<el-tag>{{fileList.word?fileList.word.name:''}}</el-tag>
          </div>
          <div v-show="fileList.pdf"> pdf: <el-tag>{{fileList.pdf?fileList.pdf.name:''}}</el-tag>
          </div>
        </div>

      </div>

      <div class="box">
        <Sheet :form="form"
               :signAbled="signAbled"
               ref="sheetRef" />
        <!-- :size="size"  -->
      </div>

      <el-row style="margin-top:30px">
        <el-button type="primary"
                   v-if="form.excel_image_two && form.excel_image_one"
                   @click="testCard">
          测试答题卡
        </el-button>
        <el-button type="primary"
                   v-if="form.excel_image_two || form.excel_image_one"
                   @click="save">
          保存答题卡
        </el-button>
        <el-button type="danger"
                   v-if="form.excel_image_two && form.excel_image_one"
                   @click="submit">
          提交答题卡
        </el-button>

      </el-row>

    </div>
    <FileDialog ref="fileRef"
                @importSubmit="importSubmit" />

    <TypeTip ref="typeTipRef"
             @changeType="changeType" />
  </div>
</template>

<script>
import { uploadImageAuto, uploadExcelAuto, uploadExcel } from '@/api/upload.js'
import { points, points1 } from './components/pointList.js'

import Sheet from './components/sheet.vue'
import FileDialog from './components/fileDialog.vue'
import TypeTip from './components/typeTip.vue'

import { saveSheet, continueMake, cancelApplyMake, getSheetTemplate } from '@/api/sheet.js'
import Vue from 'vue'
export default {
  data () {
    return {
      activeTab: "2",//当前面板，默认自制模板
      typeList: ["通用模板", "推荐模板", "自制模板"],


      showApplyStatus: [0, -1, ''], // 当答题卡状态为以下内容时，才显示申请按钮

      form: {
        pdf_file: '',
        // 教师试卷ID
        edu_paper_id: '',
        // 上传的excel 文件url地址
        excel_file: '',
        // 正面图片地址
        excel_image_one: '',
        // 反面图片地址
        excel_image_two: '',
        // 正面标注图片上传后地址
        mark_image_one: '',
        // 反面标注图片上传后地址
        mark_image_two: '',
        // 正面标注图片标注后json 数据
        mark_image_one_json: JSON.stringify(points),
        // 反面标注图片标注后json 数据
        mark_image_two_json: JSON.stringify(points1),
        // 教师试卷答题卡ID 修改必传
        sheet_id: '',
        status: '',
      },

      signAbled: true,//是否可以标注

      templateData: [],//推荐模板

      sizeData: [
        { size: "A3", url: 'https://sunraydtk.oss-cn-beijing.aliyuncs.com/temp/common_template_A3.docx', url2: 'https://sunraydtk.oss-cn-beijing.aliyuncs.com/temp/common_template_correct_A3.docx' },
        { size: "A4", url: 'https://sunraydtk.oss-cn-beijing.aliyuncs.com/temp/common_template_A4.docx', url2: 'https://sunraydtk.oss-cn-beijing.aliyuncs.com/temp/common_template_correct_A4.docx' }
      ],

      hasSelectId: '',
      size: 0,
      // type: "",
      fileList: {
        word: undefined,
        excel: undefined,
        pdf: undefined
      },
      formNormal: {
      }
    }

  },
  components: {
    Sheet, FileDialog, TypeTip
  },
  async mounted () {

    // await this.getSheetTemplate()
    let sheet_id = this.$route.query.sheet_id
    if (Number(sheet_id)) {
      this.countinueMakeSheet(sheet_id)
    }
    else if (this.$route.query.type == 'autonomously') {
      this.showTypeDialog()
    }
    else {
      this.activeTab = 2
    }
    // this.type = this.$route.query.type

    let status = this.$route.query.status
    if (Number(status)) {
      this.form.status = status
    }
  },
  methods: {
    //选择模式类型，弹窗提交执行
    changeType (type) {
      this.resetForm()
      this.activeTab = type
      this.$refs.typeTipRef.dialogVisible = false
    },
    showTypeDialog () {

      this.$refs.typeTipRef.pageType = this.$route.query.type //页面是否为自主组卷页面，其他无推荐模板
      this.$refs.typeTipRef.templateLen = this.templateData.length

      this.$refs.typeTipRef.dialogVisible = true
    },

    // 根据试卷id筛选答题卡模板
    // async getSheetTemplate () {
    //   const { data } = await getSheetTemplate({
    //     edu_paper_id: Number(this.$route.query.id)
    //   })
    //   this.templateData = data
    // },
    // 申请制作答题卡
    // async apply () {
    //   let data = {
    //     edu_paper_id: this.$route.query.id
    //   }
    //   await applyMake(data)
    //   this.$message.success("已提交申请")
    // },
    // 取消申请制作答题卡
    async cancelApply () {
      let data = {
        edu_paper_id: this.$route.query.id
      }
      await cancelApplyMake(data)
      this.$message.success("已提交取消申请")
    },

    async countinueMakeSheet (id) {
      let params = {
        sheet_id: id
      }
      const { data } = await continueMake(params)
      this.form = JSON.parse(JSON.stringify(data))
      this.size = this.form.size
      this.formNormal = data
      this.activeTab = this.form.type
      if (this.activeTab == 0) { //原来是通用模板的现在都为自制模板
        this.activeTab = 2
      }
      if (this.form.paper_sheet_id) {
        this.hasSelectId = this.form.paper_sheet_id
        // this.activeTab = "1"
        this.signAbled = false
      }

      console.log(JSON.parse(data.mark_image_one_json))
      this.$nextTick(() => {
        this.$refs.sheetRef.getFixedImage()
      })
    },
    // 直接使用模板
    useTemplate (index, row) {
      this.hasSelectId = row.sheet_id
      this.signAbled = false
      this.size = row.size
      let rowtemp = JSON.parse(JSON.stringify(row))
      if (JSON.parse(rowtemp.mark_image_one_json).length > 0) {
        this.form = rowtemp
      } else {
        rowtemp.mark_image_one_json = JSON.stringify(points)
        rowtemp.mark_image_two_json = JSON.stringify(points1)
        this.form = rowtemp
      }
      this.form.sheet_id = this.$route.query.sheet_id
      this.$refs.sheetRef.getFixedImage()
      console.log("form", this.form)
    },
    // 取消使用模板
    cancelUseTemplate () {
      this.hasSelectId = ''
      this.signAbled = true
      this.size = 0
      this.resetForm()
    },


    resetForm () {
      this.hasSelectId = ''
      this.form = {
        pdf_file: '',
        // 教师试卷ID
        edu_paper_id: '',
        // 上传的excel 文件url地址
        excel_file: '',
        // 正面图片地址
        excel_image_one: '',
        // 反面图片地址
        excel_image_two: '',
        // 正面标注图片上传后地址
        mark_image_one: '',
        // 反面标注图片上传后地址
        mark_image_two: '',
        // 正面标注图片标注后json 数据
        mark_image_one_json: JSON.stringify(points),
        // 反面标注图片标注后json 数据
        mark_image_two_json: JSON.stringify(points1),
        // 教师试卷答题卡ID 修改必传
        sheet_id: '',
        status: '',
      }
    },



    async importSubmit (fileList) {
      let formData = new FormData()
      if (fileList.excel) {
        formData.append("filename", "excel")
        formData.append("excel", fileList.excel)
        // if (this.formNormal.pdf_file) {
        //   formData.append("excel_url", this.formNormal.excel_file)
        // }
      } else if (fileList.word) {
        formData.append("word_file_name", "word")
        formData.append("word", fileList.word)
        // if (this.formNormal.pdf_file && this.formNormal.word_file) {
        //   formData.append("word_url", this.formNormal.word_file)
        // }
      }
      formData.append("pdf_file_name", "pdf")
      formData.append("pdf", fileList.pdf)
      formData.append("edu_paper_id", Number(this.$route.query.id))
      formData.append('sign', Vue.prototype.$getMd5Form({ edu_paper_id: this.$route.query.id, pdf_file_name: 'pdf', word_file_name: 'word' }))
      // if (this.formNormal.pdf_file) {
      //   formData.append("pdf_url", this.formNormal.pdf_file)
      // }
      const { data } = await uploadExcel(formData)
      this.$refs.fileRef.reset()
      this.$refs.fileRef.handleClose()

      this.form.excel_file = data.excel_thumb_path
      this.form.word_file = data.word_thumb_path
      this.form.pdf_file = data.pdf_thumb_path
      this.form.excel_image_one = data.image_data["01"]
      this.form.excel_image_two = data.image_data["02"]

      // 正面标注图片标注后json 数据
      this.form.mark_image_one_json = JSON.stringify(points)
      // 反面标注图片标注后json 数据
      this.form.mark_image_two_json = JSON.stringify(points1)
      this.$nextTick(() => {
        this.$refs.sheetRef.getFixedImage()
      })
    },

    importDialog (type) {
      this.$refs.fileRef.dialogVisible = true
      this.$refs.fileRef.fileType = type
    },

    openFile (url) {
      // console.log(url)
      window.open(url)
    },
    check (form) {
      let zheng = JSON.parse(form.mark_image_one_json)
      let fan = JSON.parse(form.mark_image_two_json)

      let bool = true
      let arr = ['w', 'h']
      // zheng.forEach(item => {
      //   arr.forEach(b => {
      //     if (item.points[b] == '') {
      //       bool = false
      //     }
      //   })
      // });
      // fan.forEach(item => {
      //   arr.forEach(b => {
      //     if (item.points[b] == '') {
      //       bool = false
      //     }
      //   })
      // });
      arr.forEach(b => {
        if (zheng[0].points[b] == '' || fan[0].points[b] == '') {
          bool = false
        }
      })
      return bool
    },


    //正反面都有可提交
    async submit () {
      // let size
      // if(this.activeTab=='normal'){
      //   size=0
      // }

      let form = JSON.parse(JSON.stringify(this.form))
      if (!this.check(form)) {
        console.log(form)
        this.$message.warning("请标记完整答题卡正反面")
        return
      }
      form.edu_paper_id = this.$route.query.id
      form.keep_id = 0
      form.size = this.size
      form.type = Number(this.activeTab)
      form.correct_type = this.$route.query.correct_type

      if (this.hasSelectId) {
        form.paper_sheet_id = this.hasSelectId
      }
      await saveSheet(form)
      this.$message.success("操作成功")
      this.$router.go(-1)
    },
    //只有其中一面，保存
    async save () {
      let form = JSON.parse(JSON.stringify(this.form))
      form.edu_paper_id = this.$route.query.id
      form.keep_id = 1
      form.size = this.size
      console.log(this.size)
      form.type = Number(this.activeTab)
      form.correct_type = Number(this.$route.query.correct_type)

      if (this.hasSelectId) {
        form.paper_sheet_id = this.hasSelectId
      }
      await saveSheet(form)
      this.$message.success("操作成功")
      this.$router.go(-1)
    },
    // 测试答题卡
    testCard () {
      let form = {
        url1: this.form.excel_image_one,
        url2: this.form.excel_image_two,
        mark_image_one_json: this.form.mark_image_one_json,
        mark_image_two_json: this.form.mark_image_two_json,
        type: this.size,
        correct_type: this.$route.query.correct_type,
        edu_paper_id: this.form.edu_paper_id,

      }
      window.sessionStorage.setItem("testCard", JSON.stringify(form))
      this.$router.push('/exaManage/reviewSheet?isTest=' + true)
      console.log(form)
    },

  }
}
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 10px;
}
.btn {
  padding: 10px 20px;
  background: #409eff;
  border-radius: 4px;
  color: white;
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
}

.btn_wrap {
  display: flex;
}

::v-deep .el-message--warning {
  z-index: 2001 !important;
}
.content_main {
  font-size: 14px;
  padding: 5px 20px 5px 30px;
  .text_btn {
    padding: 2px;
    margin-right: 10px;
  }
}
::v-deep .el-button {
  width: auto;
  padding: 10px 15px;
}
.box {
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 0 15px 15px;
  box-shadow: 4px 0px 6px #dbdbe0;
  margin-bottom: 40px;
}
.template_list {
  .title {
    padding: 15px 0;
  }
}
.btn_upload {
  display: flex;
  margin: 10px;
  span {
    line-height: 36px;
    padding-right: 20px;
  }
}
::v-deep .el-tabs__item.is-active {
  font-size: 18px;
}
::v-deep .el-tabs__item {
  font-size: 14px;
}
::v-deep .el-button--medium {
  padding: 10px 20px;
  height: auto;
  font-size: 14px;
  border-radius: 4px;
}
::v-deep .el-radio {
  line-height: 35px;
}
.template_type {
  padding: 0 20px 20px;
  font-weight: 700;
  font-size: 14px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:before {
    content: "";
    width: 4px;
    height: 16px;
    border-radius: 20px;
    position: absolute;
    margin-top: -18px;
    left: 0;
    top: 50%;
    background: #48acfd;
    margin-right: 20px;
  }
}
</style>